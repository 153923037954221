import React from "react";
import {
	ActivityEntityType,
	ActivityTypeEnum,
	FileWithShare,
	JobEntityType,
	PatientWithShare,
	SupabaseTableEnum,
} from "../../../lib/supabase/supabaseTypes";
import { ActivityJobLink } from "./activity-elements/activity-job-link.component";
import { ActivityMessage } from "./activity-elements/activity-message.component";
import { ActivityPatientLink } from "./activity-elements/activity-patient-link.component";
import { ActivityTitleWithUserAndDate } from "./activity-elements/activity-title-with-user-and-date.component";
import { ActivityReplies } from "./activity-elements/activity-replies.component";
import { ActivityLinkWrapper } from "./activity-elements/activity-elements-components/activity-link-wrapper.component";
import { useDatabaseFetch } from "../../../dentlab/src/hooks/useDatabase";
import { ActivityNotPdfFile } from "./activity-elements/activity-not-pdf-file.component";
import { ActivityElementWrapperWithLabel } from "./activity-elements/activity-elements-components/activity-element-wrapper-with-label.component";
import { FileCardWrapper } from "@/dentlab/src/components/file-display-components/file-card-wrapper.component";
import { ActivityJobRequestLink } from "./activity-elements/activity-job-request-link.component";
import { ActivityJobRequestStatusLink } from "./activity-elements/activity-job-request-status.component";

// TODO: design something to add labels to the top of single data displays / find a way to split data displays better (e.g. link, message, note)
// TODO: add functionality to add a note to an activity
// TODO: create Jira issue: Enable user to pin an activity to the top of the activity list (this component would provide the trigger function onActivityPin)

/**
 * Activity
 * - displays activity information
 * - handles adding a note
 * @param activity - activity object
 * @param onOpenThread - triggered when user clicks on replies
 */
export const Activity: React.FC<{
	activity: ActivityEntityType;
	onOpenThread: () => void;
	onPatientClick: (patient: PatientWithShare) => void;
	onJobClick: (job: JobEntityType) => void;
	hideDate?: boolean;
}> = ({ activity, onOpenThread, onPatientClick, onJobClick, hideDate }) => {
	const { data: fileData, loading: isFileLoading } = useDatabaseFetch(
		SupabaseTableEnum.FILES,
		{
			column: "id",
			value: activity.file_id,
		}
	);
	const file: FileWithShare = fileData?.[0];

	const { data: jobData, loading: isJobLoading } = useDatabaseFetch(
		SupabaseTableEnum.JOBS,
		{
			column: "id",
			value: activity.job_id ?? file?.job_id,
		}
	);
	const job: JobEntityType = jobData?.[0];

	const { data: patientData, loading: isPatientLoading } = useDatabaseFetch(
		SupabaseTableEnum.PATIENTS,
		{
			column: "id",
			value: activity.patient_id ?? file?.patient_id,
		}
	);
	const patient: PatientWithShare = patientData?.[0];

	const { data: jobRequestData, loading: isJobRequestLoading } =
		useDatabaseFetch(SupabaseTableEnum.JOB_REQUESTS, {
			column: "id",
			value: activity.job_request_id,
		});
	const jobRequest = jobRequestData?.length
		? JSON.parse(jobRequestData?.[0]?.request)
		: null;

	return (
		<div
			style={{
				width: "500px",
				display: "flex",
				flexDirection: "column",
				gap: "20px",
			}}
		>
			<ActivityTitleWithUserAndDate
				activity={activity}
				hideDate={hideDate}
			/>
			{activity.activity_type === ActivityTypeEnum.JOB_REQUEST_STATUS ? (
				<ActivityLinkWrapper
					onClick={() => {}}
					label="Auftragsanfrage aktualisiert"
					isLoading={isJobRequestLoading}
				>
					<ActivityJobRequestStatusLink jobRequest={jobRequest} />
				</ActivityLinkWrapper>
			) : null}
			{jobRequest &&
			activity.activity_type === ActivityTypeEnum.SHARED_JOB_REQUEST ? (
				<ActivityLinkWrapper
					onClick={() => onJobClick(job)}
					isLoading={isJobRequestLoading}
					label="Verlinkte Auftragsanfrage"
				>
					<ActivityJobRequestLink
						job={jobRequest}
						accepted={jobRequestData?.[0].accepted !== null}
						rejected={jobRequestData?.[0].rejected !== null}
					/>
				</ActivityLinkWrapper>
			) : null}
			{job?.id ? (
				<ActivityLinkWrapper
					onClick={() => onJobClick(job)}
					isLoading={isJobLoading}
					label="Verlinkter Auftrag"
				>
					<ActivityJobLink job={job} />
				</ActivityLinkWrapper>
			) : null}
			{patient?.id ? (
				<ActivityLinkWrapper
					onClick={() => onPatientClick(patient)}
					isLoading={isPatientLoading}
					label="Verlinkter Patient"
				>
					<ActivityPatientLink patient={patient} />
				</ActivityLinkWrapper>
			) : null}
			{file?.id &&
				(activity.activity_type == ActivityTypeEnum.SHARED_DOCUMENT ||
					activity.activity_type == ActivityTypeEnum.SRG) && (
					<ActivityElementWrapperWithLabel label="Dateien">
						<div
							style={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							<div
								style={{
									width: "190px",
								}}
							>
								<FileCardWrapper
									fileId={file.id}
									onDelete={() => {}}
								/>
							</div>
						</div>
					</ActivityElementWrapperWithLabel>
				)}
			{file?.id &&
				activity.activity_type == ActivityTypeEnum.SHARED_FILE && (
					<ActivityNotPdfFile fileId={file.id} />
				)}
			{activity.text ? <ActivityMessage message={activity.text} /> : null}
			<ActivityReplies activity={activity} onThreadOpen={onOpenThread} />
		</div>
	);
};
