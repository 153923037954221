import { useAccountingStore } from "@/dentlab/src/store/Accounting";
import { AccountingViewType } from "@/lib/supabase/supabaseTypes";
import { CancelOutlined } from "@mui/icons-material";
import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Tooltip,
} from "@mui/material";
import { FileIcon } from "lucide-react";
import { useMemo, useState } from "react";
import ReceiptIcon from "@mui/icons-material/Receipt";

import { LoadingSkeletonRows } from "@/components/src/loading/loading-skeleton-rows.component";
import { useStorage } from "@/dentlab/src/hooks/useStorage";
import * as locales from "@mui/material/locale";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { AccountingStatus } from "../types";
import { EmptyDataPlaceholder } from "@/components/src/empty-with-label/empty-data-placeholder.component";
import { Share2Icon } from "lucide-react";
import { showNotification } from "@/dentlab/src/store/Central/selectors";
import { supabase } from "@/lib/supabase/supabaseClient";

const headCells = [
	{
		label: "Rechnungsnr.",
		key: "invoice_doc_number",
	},
	{
		label: "Rechnungsdatum",
		key: "invoice_date",
	},
	{
		label: "Betrag",
		key: "invoice_value",
	},
	{
		label: "Empfänger",
		key: "client_name",
	},
	{
		label: "Zahlstatus",
		key: "status",
	},
];

export const AccountingTable: React.FC<{
	accountingData: AccountingViewType[];
	loading?: boolean;
}> = ({ accountingData, loading }) => {
	const [page, setPage] = useState(0);
	const { openFileInNewTab } = useStorage();
	const { markInvoiceAsPaid, cancelInvoice } = useAccountingStore(
		(state) => ({
			markInvoiceAsPaid: state.markInvoiceAsPaid,
			cancelInvoice: state.cancelInvoice,
		})
	);
	const rowsPerPage = 100;
	const cursor = page * rowsPerPage;

	const theme = useTheme();

	const themeWithLocale = useMemo(
		() => createTheme(theme, locales.deDE),
		[theme]
	);

	const [order, setOrder] = useState<"asc" | "desc">("asc");

	const sortedData = useMemo(() => {
		return [...accountingData].sort((a, b) => {
			const aDocNumber = a.invoice_doc_number ?? ""; // Use an empty string for null values
			const bDocNumber = b.invoice_doc_number ?? ""; // Use an empty string for null values
			const isAsc = order === "asc";
			if (aDocNumber < bDocNumber) return isAsc ? -1 : 1;
			if (aDocNumber > bDocNumber) return isAsc ? 1 : -1;
			return 0;
		});
	}, [accountingData, order]);

	const handleSortRequest = () => {
		setOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
	};

	if (!loading && accountingData.length === 0) {
		return <EmptyDataPlaceholder label="Keine Rechnungen vorhanden" />;
	}

	return (
		<ThemeProvider theme={themeWithLocale}>
			<Table>
				<TableHead>
					<TableRow>
						{headCells.map((headCell) =>
							headCell.key === "invoice_doc_number" ? (
								<TableCell key={headCell.label}>
									<TableSortLabel
										active
										direction={order}
										onClick={handleSortRequest}
									>
										{headCell.label}
									</TableSortLabel>
								</TableCell>
							) : (
								<TableCell key={headCell.label}>
									{headCell.label}
								</TableCell>
							)
						)}
						<TableCell></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{loading ? (
						<LoadingSkeletonRows rows={10} columns={6} />
					) : (
						sortedData
							.slice(cursor, cursor + rowsPerPage)
							.map((row) => {
								return (
									<TableRow key={row.invoice_id}>
										<TableCell>
											{row.invoice_doc_number}
										</TableCell>
										<TableCell>
											{new Date(
												row.invoice_date ?? ""
											).toLocaleDateString("de-ch")}
										</TableCell>
										<TableCell>
											{row.invoice_value?.toFixed(2)}
										</TableCell>
										<TableCell>
											{row.client_name ??
												row.guarantor_name}
										</TableCell>
										<TableCell className="flex justify-center">
											<div className="bg-neutral-200 w-max rounded-full py-0.5 px-4 flex justify-center items-center">
												{row.status}
											</div>
										</TableCell>
										<TableCell>
											<div className="flex gap-2">
												<Tooltip
													title={
														"Rechnungsdatei öffnen"
													}
												>
													<IconButton
														onClick={() => {
															if (!row.path_name)
																return;
															openFileInNewTab(
																row.path_name
															);
														}}
													>
														<FileIcon />
													</IconButton>
												</Tooltip>
												<Tooltip
													title={
														"Als bezahlt markieren"
													}
												>
													<IconButton
														disabled={
															row.status ===
																AccountingStatus.STORNIERT ||
															row.status ===
																AccountingStatus.BEZAHLT
														}
														onClick={() => {
															if (!row.invoice_id)
																return;
															markInvoiceAsPaid(
																row.invoice_id
															);
														}}
													>
														<ReceiptIcon />
													</IconButton>
												</Tooltip>
												<Tooltip
													title={
														"Rechnung stornieren"
													}
												>
													<IconButton
														disabled={
															row.status?.toLowerCase() ===
															"storniert"
														}
														onClick={() => {
															if (!row.invoice_id)
																return;
															cancelInvoice(
																row.invoice_id
															);
														}}
													>
														<CancelOutlined />
													</IconButton>
												</Tooltip>
												<Tooltip title={"Teilen"}>
													<IconButton
														onClick={async () => {
															if (
																!row.invoice_id
															) {
																showNotification(
																	{
																		message:
																			"Rechnung konnte nicht identifiziert werden",
																		type: "error",
																	}
																);
																return;
															}
															const {
																data,
																error,
															} =
																await supabase.rpc(
																	"share_srg",
																	{
																		invoice_id:
																			row.invoice_id,
																	}
																);
															if (error) {
																showNotification(
																	{
																		message:
																			"Rechnung konnte nicht geteilt werden",
																		type: "error",
																	}
																);
																return;
															} else {
																if (
																	data ===
																	"success"
																) {
																	showNotification(
																		{
																			message:
																				"Rechnung wurde geteilt",
																			type: "success",
																		}
																	);
																} else if (
																	data ===
																	"Connect relationship not found"
																) {
																	showNotification(
																		{
																			message:
																				"Der Auftraggeber ist nicht mit dentCONNECT verbunden.",
																			type: "error",
																			description:
																				data,
																		}
																	);
																} else {
																	showNotification(
																		{
																			message:
																				"Rechnung konnte nicht geteilt werden",
																			type: "error",
																			description:
																				data,
																		}
																	);
																}
															}
														}}
													>
														<Share2Icon />
													</IconButton>
												</Tooltip>
											</div>
										</TableCell>
									</TableRow>
								);
							})
					)}
					<TablePagination
						// Need to have an array of 1 element to prevent the pagination from showing rows per page options
						rowsPerPageOptions={[rowsPerPage]}
						component="div"
						count={accountingData.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={(e, n) => {
							if (n <= 0) {
								setPage(0);
								return;
							}
							setPage(n);
						}}
					/>
				</TableBody>
			</Table>
		</ThemeProvider>
	);
};
