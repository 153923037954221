import { StateCreator } from "zustand";
import { handleDatabaseOperation } from "../../lib/utils/utils-functions";
import { supabase } from "@/lib/supabase";
import {
	AccountingViewType,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { Logger } from "@/lib/logger/Logger";
import { useCentralStore } from "../Central";
import { AccountingStatus } from "../../pages/accounting/types";
import { RecipientTypeEnum } from "../../pages/monthly-invoices/types";
import {
	BankAccountEntityType,
	ClientEntityType,
	JobDocumentEntityType,
	JobEntityType,
	OrganizationEntityType,
	SupabaseRpcEnum,
} from "@/lib/supabase/supabaseTypes";
import { PdfTemplateType } from "../../pdf-templates/document-template.types";
import { InvoiceType } from "../../types/database-enums/invoices-enums";
import { showNotification } from "../Central/selectors";

type RecipientsLookupValue = { type: RecipientTypeEnum; name: string };

const DEFAULT_CURRENCY = "CHF";

export enum PaymentType {
	ZAHLUNG = 3,
	BARZAHLUNG = 4,
	KARTENZAHLUNG = 5,
}

interface CreateInvoiceSingleProps {
	fileNamePrefix: string;
	invoiceValue: number;
	bankAccount: BankAccountEntityType;
	invoiceType: InvoiceType;
	pdfTemplateType: PdfTemplateType;
	client: ClientEntityType;
	organization: OrganizationEntityType;
	jobDocument: JobDocumentEntityType;
	job: JobEntityType;
}

interface CreateInvoiceMonthlyProps {
	invoiceValue: number;
	bankAccountId: number;
	clientId: string | null;
	guarantorId: string | null;
	organizationId: string;
	jobDocumentIds: number[];
	invoiceDate: Date;
}

interface CreateInvoiceReturnType {
	success: boolean;
	data: {
		invoiceNumber: string;
		filePath: string;
	} | null;
	error: string | null;
}

export interface AccountingSlice {
	accountingData: AccountingViewType[];
	recipientsLookup: Record<string, RecipientsLookupValue>;
	fetchRecipients: () => Promise<void>;
	fetchAccountingData: (
		statuses: AccountingStatus[],
		startDate: string,
		endDate: string,
		recipient: string
	) => Promise<void>;
	markInvoiceAsPaid: (invoiceId: number) => Promise<void>;
	cancelInvoice: (invoiceId: number) => Promise<void>;
	refreshSingleAccountingData: (invoiceId: number) => Promise<void>;
	createInvoiceSingle: (
		props: CreateInvoiceSingleProps
	) => Promise<CreateInvoiceReturnType>;
	createInvoiceMonthly: (
		props: CreateInvoiceMonthlyProps
	) => Promise<CreateInvoiceReturnType>;
}

export const createAccountingStore: StateCreator<AccountingSlice> = (
	set,
	get
) => ({
	accountingData: [],
	recipientsLookup: {},
	refreshSingleAccountingData: async (invoiceId) => {
		const { data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.ACCOUNTING_VIEW)
				.select()
				.eq("invoice_id", invoiceId)
		);

		if (error) {
			Logger.error(error);
			return;
		}

		if (data) {
			set((state) => ({
				...state,
				accountingData: state.accountingData.map((invoice) => {
					if (invoice.invoice_id === invoiceId) {
						return data[0];
					}
					return invoice;
				}),
			}));
		}
	},
	fetchAccountingData: async (
		statuses,
		startDate,
		endDate,
		selectedRecipient
	) => {
		const organizationId = useCentralStore.getState().organization?.id;
		if (!organizationId) return Logger.error("Organization ID not found");
		let query = supabase
			.from(SupabaseTableEnum.ACCOUNTING_VIEW)
			.select()
			.eq("organization_id", organizationId)
			.order("invoice_date", { ascending: false })
			.gte("invoice_date", startDate)
			.lte("invoice_date", endDate);

		if (statuses.length > 0) {
			const filter = statuses
				.map((status) => `status.ilike.${status}%`)
				.join(",");
			query = query.or(filter);
		}
		await get().fetchRecipients();

		const recipient = get().recipientsLookup[selectedRecipient];

		if (selectedRecipient && recipient) {
			if (recipient.type === "client") {
				query.eq("client_id", selectedRecipient);
			} else if (recipient.type === "guarantor") {
				query.eq("guarantor_id", selectedRecipient);
			}
		}

		const { data, error } = await handleDatabaseOperation(query);

		if (error) {
			Logger.error(error);
			return;
		}

		set({ accountingData: data ?? [] });
	},
	fetchRecipients: async () => {
		const organizationId = useCentralStore.getState().organization?.id;
		if (!organizationId) return Logger.error("Organization ID not found");

		const { data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.ACCOUNTING_RECIPIENTS)
				.select("*")
				.eq("organization_id", organizationId)
		);

		if (error) {
			Logger.error(error);
			return;
		}

		if (data) {
			const recipientsLookup = data.reduce(
				(
					acc: Record<string, RecipientsLookupValue>,
					curr: {
						recipient_type: "c" | "g";
						recipient_id: string;
						recipient_name: string;
					}
				) => {
					acc[curr.recipient_id] = {
						type:
							curr.recipient_type === "c"
								? RecipientTypeEnum.CLIENT
								: RecipientTypeEnum.GUARANTOR,
						name: curr.recipient_name,
					};
					return acc;
				},
				{}
			);

			set({ recipientsLookup: recipientsLookup });
		}
	},
	createInvoiceSingle: async ({
		fileNamePrefix,
		invoiceValue,
		bankAccount,
		invoiceType,
		pdfTemplateType,
		client,
		organization,
		jobDocument,
		job,
	}: CreateInvoiceSingleProps): Promise<CreateInvoiceReturnType> => {
		const rpcParams = {
			file_name_prefix: fileNamePrefix,
			invoice_value: invoiceValue,
			invoice_bank_account_id: bankAccount.id,
			invoice_type: invoiceType,
			pdf_template_type: pdfTemplateType,
			invoice_currency: DEFAULT_CURRENCY,
			invoice_client_id: client.id,
			invoice_guarantor_id: null as unknown as string, // TODO: can normal invoices go to guarantors?
			invoice_organization_id: organization.id,
			job_document_id: jobDocument.id,
			job_id: job.id as number,
		};
		const { data, error } = await supabase.rpc(
			SupabaseRpcEnum.CREATE_INVOICE_SINGLE,
			rpcParams
		);
		if (error) {
			return { success: false, data: null, error: error.message };
		}

		const invoiceNumber = data[0].invoice_number;
		const filePath = data[0].file_path;

		if (!invoiceNumber || !filePath) {
			return {
				success: false,
				data: null,
				error: "No invoice number or file path returned",
			};
		}

		return {
			success: true,
			data: { invoiceNumber, filePath },
			error: null,
		};
	},
	createInvoiceMonthly: async ({
		invoiceValue,
		bankAccountId,
		clientId,
		guarantorId,
		organizationId,
		jobDocumentIds,
		invoiceDate,
	}: CreateInvoiceMonthlyProps): Promise<CreateInvoiceReturnType> => {
		if (!clientId && !guarantorId) {
			return {
				success: false,
				data: null,
				error: "Either client or guarantor must be set.",
			};
		}

		const { data, error } = await supabase.rpc(
			SupabaseRpcEnum.CREATE_INVOICE_MONTHLY,
			{
				file_name_prefix: "Sammelrechnung-",
				invoice_value: invoiceValue,
				invoice_bank_account_id: bankAccountId,
				invoice_type: InvoiceType.MONATSRG,
				pdf_template_type: PdfTemplateType.SAMMELRECHNUNG,
				invoice_currency: DEFAULT_CURRENCY,
				invoice_client_id: clientId as string,
				invoice_guarantor_id: guarantorId as string,
				invoice_organization_id: organizationId,
				job_document_ids: jobDocumentIds,
				invoice_date: invoiceDate.toISOString(),
			}
		);
		if (error) {
			Logger.error(error, {}, "Error creating monthly invoice via rpc");
			return { success: false, data: null, error: error.message };
		}

		const invoiceNumber = data?.f1 as string;
		const filePath = data?.f2 as string;

		if (!invoiceNumber || !filePath) {
			return {
				success: false,
				data: null,
				error: "No invoice number or file path returned",
			};
		}

		return {
			success: true,
			data: { invoiceNumber, filePath },
			error: null,
		};
	},
	markInvoiceAsPaid: async (invoiceId) => {
		const { error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.INVOICES)
				.update({ paid_at: new Date().toISOString() })
				.match({ id: invoiceId })
		);

		if (error) {
			Logger.error(error);
			return;
		}

		await get().refreshSingleAccountingData(invoiceId);
	},
	cancelInvoice: async (invoiceId) => {
		const { error } = await supabase.rpc("cancel_invoice", {
			invoice_id_param: invoiceId,
		});
		if (error) {
			Logger.error(error);
			showNotification({
				message: "Fehler beim Storno der Rechnung",
				type: "error",
			});
			return;
		}

		await get().refreshSingleAccountingData(invoiceId);
	},
});
