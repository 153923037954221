import { PageTitle } from "@/components/src/page-title/page-title.component";
import {
	Card,
	CardActions,
	CardContent,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Stack,
	Table,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
} from "@mui/material";
import { useTemplatesStore } from "../../store/Templates/templates.store";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { TableBody } from "@mui/material";
import { Save } from "lucide-react";
import { EditableTitle } from "@/components/src/editable-title/editable-title.component";
import { displayTemplateLabel } from "../../store/Templates/utils";
import { useCentralStore } from "../../store/Central";

export const JobDocumentTemplatesPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const templateId = searchParams.get("templateId");
	const [searchQuery, setSearchQuery] = useState("");

	const { templatesLookup, deleteTemplate } = useTemplatesStore((state) => ({
		templatesLookup: state.templatesLookup,
		deleteTemplate: state.deleteTemplate,
	}));
	const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(
		null
	);

	useEffect(() => {
		if (templateId && templatesLookup[Number(templateId)]) {
			setSelectedTemplateId(Number(templateId));
		}
	}, [templateId]);

	const filteredTemplates = Object.values(templatesLookup).filter(
		(template) =>
			template.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
			template.code?.toLowerCase().includes(searchQuery.toLowerCase())
	);

	const handleDeleteTemplate = async (templateId: number) => {
		setSelectedTemplateId(null);
		await deleteTemplate(templateId);
		setSearchParams((prev) => {
			prev.delete("templateId");
			return prev;
		});
	};

	return (
		<div className="p-6 flex flex-col gap-4">
			<PageTitle title={[{ name: "Dokumentenvorlagen" }]} />
			<div className="flex flex-row gap-4">
				<Card sx={{ width: 300, height: "100%" }}>
					<TextField
						size="small"
						placeholder="Suchen..."
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
						sx={{ m: "12px", width: "calc(100% - 24px)" }}
						variant="standard"
					/>
					<List
						sx={{
							overflow: "auto",
							maxHeight: "calc(100vh - 230px)",
						}}
					>
						{filteredTemplates.map((template) => (
							<ListItem key={template.id} disablePadding>
								<ListItemButton
									selected={
										selectedTemplateId === template.id
									}
									onClick={() => {
										setSelectedTemplateId(template.id);
										setSearchParams((prev) => {
											prev.set(
												"templateId",
												template.id.toString()
											);
											return prev;
										});
									}}
								>
									<ListItemText
										primary={displayTemplateLabel(
											template.code,
											template?.name ?? undefined
										)}
									/>
								</ListItemButton>
							</ListItem>
						))}
					</List>
				</Card>
				<div className="flex flex-col gap-4">
					{selectedTemplateId && (
						<JobDocumentTemplateCard
							templateId={selectedTemplateId}
							onDelete={() =>
								handleDeleteTemplate(selectedTemplateId)
							}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

const JobDocumentTemplateCard: React.FC<{
	templateId: number;
	onDelete: () => void;
}> = ({ templateId, onDelete }) => {
	const {
		updateTemplateItemQuantity,
		deleteTemplateItem,
		updateTemplateName,
		updateTemplateCode,
		templatesLookup,
	} = useTemplatesStore((state) => ({
		deleteTemplate: state.deleteTemplate,
		updateTemplateItemQuantity: state.updateTemplateItemQuantity,
		deleteTemplateItem: state.deleteTemplateItem,
		updateTemplateName: state.updateTemplateName,
		updateTemplateCode: state.updateTemplateCode,
		templatesLookup: state.templatesLookup,
	}));
	const { tariffs, articles } = useCentralStore((state) => ({
		tariffs: state.tariffs,
		articles: state.articles,
	}));

	const template = templatesLookup[templateId];
	const {
		name: templateName,
		code: templateCode,
		template_items: templateItems,
	} = template;

	const [title, setTitle] = useState(templateName);
	const [code, setCode] = useState(templateCode);

	useEffect(() => {
		setTitle(templateName);
		setCode(templateCode);
	}, [templateId]);

	const handleTitleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(e.target.value);
	};

	const handleCodeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCode(e.target.value);
	};

	const handleTitleSubmit = async () => {
		if (title) await updateTemplateName(templateId, title);
	};

	const handleCodeSubmit = async () => {
		if (code) await updateTemplateCode(templateId, code);
	};

	const handleTitleReset = () => {
		setTitle(templateName);
	};

	const handleCodeReset = () => {
		setCode(templateCode);
	};

	return (
		<Card elevation={2}>
			<CardContent>
				<div className="flex flex-row gap-2">
					<EditableTitle
						name="title"
						key={`title-${templateId}`}
						value={title ?? ""}
						onChange={handleTitleInputChange}
						onSubmit={handleTitleSubmit}
						onReset={handleTitleReset}
					/>
					<EditableTitle
						name="code"
						key={`code-${templateId}`}
						value={code ?? ""}
						onChange={handleCodeInputChange}
						onSubmit={handleCodeSubmit}
						onReset={handleCodeReset}
						type="code"
					/>
				</div>
				<Stack
					direction="row"
					spacing={1}
					sx={{ mt: 2 }}
					flexWrap="wrap"
					gap={1}
				>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell>Code</TableCell>
								<TableCell>Menge</TableCell>
								<TableCell align="right">Aktionen</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{templateItems.map((item) => (
								<TableRow key={item.id}>
									<TableCell>
										<Tooltip
											title={`${!tariffs[item.code_e] && !articles[item.code_e] ? "Tarif oder Artikel nicht gefunden" : ""}`}
										>
											<span
												className={`${!tariffs[item.code_e] && !articles[item.code_e] ? "text-red-500" : ""}`}
											>
												{item.code_e}
											</span>
										</Tooltip>
									</TableCell>
									<TableCell>
										<TemplateItemQuantityInput
											quantity={item.quantity}
											onChange={(quantity) =>
												updateTemplateItemQuantity(
													templateId,
													item.id,
													quantity
												)
											}
										/>
									</TableCell>
									<TableCell align="right">
										<IconButton
											size="small"
											onClick={() =>
												deleteTemplateItem(
													templateId,
													item.id
												)
											}
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Stack>
			</CardContent>
			<CardActions>
				<IconButton onClick={onDelete}>
					<DeleteIcon />
				</IconButton>
			</CardActions>
		</Card>
	);
};

const TemplateItemQuantityInput: React.FC<{
	quantity: number;
	onChange: (quantity: number) => void;
}> = ({ quantity, onChange }) => {
	const [value, setValue] = useState(quantity);
	return (
		<TextField
			type="number"
			size="small"
			value={value}
			className="w-24"
			InputProps={{
				endAdornment: (
					<IconButton
						onClick={() => onChange(value)}
						disabled={value === quantity}
					>
						<Save size={20} />
					</IconButton>
				),
			}}
			onChange={(e) => setValue(parseInt(e.target.value))}
		/>
	);
};
