import { supabase } from "../../../lib/supabase";
import {
	handleDatabaseOperation,
	sanitizeFileName,
} from "../lib/utils/utils-functions";
import { DBOperationResult, FileIdReferencesType } from "../types/types";
import { useCallback } from "react";
import { Logger } from "../../../lib/logger/Logger";
import { StorageBucketsEnum } from "../types/enums";
import {
	FileEntityType,
	FileWithShare,
	SharesWithFileInfo,
} from "../../../lib/supabase/supabaseTypes";
import { FileOptions } from "@supabase/storage-js/dist/main/lib/types";
import { showNotification } from "../store/Central/selectors";

const BUCKET_NAME = StorageBucketsEnum.V1;

/**
 * From Supabase package
 */
type FileBody =
	| ArrayBuffer
	| ArrayBufferView
	| Blob
	| Buffer
	| File
	| FormData
	| NodeJS.ReadableStream
	| ReadableStream<Uint8Array>
	| URLSearchParams
	| string;

interface UploadFileWithProvidedPathProps {
	filePath: string;
	fileBody: FileBody;
	fileOptions?: FileOptions;
}

/**
 * Extends entity in supabase "files" table with url property retrieved via .createSignedUrl()
 */
export type FileEntityTypeWithUrl = FileEntityType & { url: string };

export type FileWithShareWithUrl = FileWithShare & { url: string };

export type SharesWithFileInfoWithUrl = SharesWithFileInfo & { url: string };

const bareUpload = async (
	path: string,
	file: FileBody,
	fileOptions?: FileOptions
) => {
	Logger.info(`sup ${BUCKET_NAME}/${path}`);
	// TODO: sanitizing the path here is problematic,
	// because it doesn't guarantee the path in the files table is identically sanitized
	const sanitizedPath = sanitizeFileName(path);
	const { data, error } = await supabase.storage
		.from(BUCKET_NAME)
		.upload(sanitizedPath, file, fileOptions);
	if (data && !error) {
		return { success: true, pathName: sanitizedPath };
	} else {
		Logger.error(error, {}, "Error uploading file");
		return { success: false, pathName: null };
	}
};

export const uploadFileWithProvidedPath = async ({
	filePath,
	fileBody,
	fileOptions,
}: UploadFileWithProvidedPathProps): Promise<{
	success: boolean;
	pathName: string | null;
}> => {
	return bareUpload(filePath, fileBody, fileOptions);
};

/**
 * useStorage - Custom hook to upload and fetch files from Supabase Storage
 * @returns uploadFiles, fetchFiles, deleteFiles, fetchJobFiles, fetchClientFiles
 * @example const { uploadFile, fetchFiles, deleteFiles } = useStorage();
 */
export const useStorage = () => {
	/**
	 * fileUrl - Retrieve a signed url for file for 1 hour
	 * @param fileName - Name of the file as saved in the supabase storage bucket
	 * @param quality - For images only, quality of the image less means faster fetch
	 * @example const {  data, error } = await fileUrl( "public/avatar_pic.png");
	 */
	const fileUrl = useCallback(
		async (fileName: string, quality: number = 80) => {
			try {
				const { data, error } = await supabase.storage
					.from(BUCKET_NAME)
					.createSignedUrl(fileName, 3600, {
						transform: {
							quality,
						},
					});

				return { data, error };
			} catch (err: unknown) {
				return { data: null, error: err };
			}
		},
		[BUCKET_NAME]
	);

	/**
	 * @returns
	 * { success: boolean, data: FileEntityTypeWithUrl | null, error: string | null}
	 */
	const fetchFile = useCallback(
		async (
			file: FileWithShare | FileEntityType | SharesWithFileInfoWithUrl
		): Promise<
			DBOperationResult<
				| FileWithShareWithUrl
				| FileEntityTypeWithUrl
				| SharesWithFileInfoWithUrl
			>
		> => {
			if (!file.path_name) {
				Logger.error("No file to fetch");
				return {
					success: false,
					error: "No file to fetch",
					data: null,
					status: 404,
				};
			}
			Logger.info(`fetchFile 2 ${BUCKET_NAME}/${file.path_name}`);
			const { data, error } = await supabase.storage
				.from(BUCKET_NAME)
				.createSignedUrl(file.path_name, 3600);
			if (data?.signedUrl) {
				const newFile = {
					...file,
					url: data.signedUrl,
				};
				return { success: true, data: newFile, error, status: 200 };
			}
			Logger.error(error, {}, "Error fetching file");
			// TODO: check if this is the correct status code or if it should be taken from await supabase.storage
			return {
				success: false,
				error: "Error fetching file",
				data: null,
				status: 500,
			};
		},
		[]
	);

	/**
	 * @returns
	 * { success: boolean, data: FileEntityTypeWithUrl | null, error: string | null}
	 */
	const fetchFileFromPath = useCallback(
		async (filePathName: string): Promise<DBOperationResult<string>> => {
			const { data, error } = await supabase.storage
				.from(BUCKET_NAME)
				.createSignedUrl(filePathName, 3600);
			if (data?.signedUrl) {
				return {
					success: true,
					data: data.signedUrl,
					error,
					status: 200,
				};
			}
			Logger.error(error, {}, "Error fetching file");
			// TODO: check if this is the correct status code or if it should be taken from await supabase.storage
			return {
				success: false,
				error: "Error fetching file",
				data: null,
				status: 500,
			};
		},
		[]
	);

	/**
	 * @returns
	 * { success: boolean, data: FileEntityTypeWithUrl[] | null, error: string | null}
	 */
	const fetchFiles = useCallback(
		async (
			files: FileWithShare[]
		): Promise<DBOperationResult<FileWithShareWithUrl[]>> => {
			const results = await Promise.all(
				files.map((file) => fetchFile(file))
			);
			const validResults = results.filter(
				(result) => result.data && result.data.url
			);
			// check if number of results is the same as number of valid files
			if (results.length !== validResults.length) {
				Logger.error(
					"useStorage: Fetched files without valid url",
					{},
					results
				);
			}
			return {
				success: true,
				// because the results are filtered, we can safely cast the data as FileEntityTypeWithUrl
				data: validResults.map(
					(result) => result.data as FileWithShareWithUrl
				),
				error: null,
				status: 200,
			};
		},
		[]
	);

	/**
	 * deleteFiles - Deletes one or multiple files from a Supabase Storage bucket
	 * @param paths: string[] - Paths of the files to delete
	 * @returns OperationResult
	 * @example const { success, data, error } = await deleteFile(["avatars/public/avatar.png"]);
	 */
	const deleteFiles = useCallback(async (paths: string[]) => {
		Logger.log("Deleting files:", paths);
		return handleDatabaseOperation(
			supabase.storage.from(BUCKET_NAME).remove(paths)
		);
	}, []);

	/**
	 * @returns
	 * { success: boolean, data: FileEntityTypeWithUrl[] | null, error: string | null }
	 */
	const fetchFilesByProps = useCallback(
		async ({
			organization_id: organizationId,
			client_id: clientId,
			job_id: jobId,
			patient_id: patientId,
		}: FileIdReferencesType): Promise<
			DBOperationResult<FileWithShareWithUrl[]>
		> => {
			Logger.log("fetchFilesByProps", {
				clientId,
				jobId,
				patientId,
				organizationId,
			});

			let query = supabase
				.from("files")
				.select("*")
				.eq("organization_id", organizationId);

			// Check and add conditions based on provided values
			// TODO: this could be added to a custom hook
			if (clientId) {
				query = query.eq("client_id", clientId);
			}
			if (jobId) {
				query = query.eq("job_id", jobId);
			}
			if (patientId) {
				query = query.eq("patient_id", patientId);
			}

			const { data, error, status } =
				await handleDatabaseOperation(query);

			Logger.log("fetchFilesByProps", { data, error });

			if (error) {
				Logger.error("Error fetching files", {}, { error });
				return {
					success: false,
					error: "Error fetching files",
					data: null,
					status,
				};
			}

			if (!data) {
				Logger.error("No files found");
				return {
					success: false,
					error: "No files found",
					data: null,
					status: 404,
				};
			}

			return fetchFiles(data);
		},
		[]
	);

	const openFileInNewTab = async (filePathName: string) => {
		const {
			success,
			data: signedUrl,
			error,
		} = await fetchFileFromPath(filePathName);
		if (success && signedUrl) {
			window.open(signedUrl, "_blank")?.focus();
		} else {
			showNotification({
				message: "Fehler beim Öffnen der Datei",
				type: "error",
			});
			Logger.log("Error opening file", error);
		}
	};

	const downloadFile = async (filePathName: string) => {
		try {
			const {
				success,
				data: signedUrl,
				error,
			} = await fetchFileFromPath(filePathName);

			if (success && signedUrl) {
				const response = await fetch(signedUrl);
				if (!response.ok)
					throw new Error("Failed to fetch file for download");

				const blob = await response.blob();
				const downloadUrl = URL.createObjectURL(blob);

				const link = document.createElement("a");
				link.href = downloadUrl;
				link.download =
					filePathName.split("/").pop() || "Unbenannte_Datei";

				// Only using .click() only works in modern browsers
				// So we add the element to the DOM first
				document.body.appendChild(link);
				link.click();

				document.body.removeChild(link);

				// Frees the memory allocated for the downloadUrl
				URL.revokeObjectURL(downloadUrl);
			} else {
				showNotification({
					message: "Fehler beim Herunterladen der Datei",
					type: "error",
				});
				Logger.log("Error downloading file", error);
			}
		} catch (err) {
			showNotification({
				message:
					"Beim Herunterladen der Datei ist ein Fehler aufgetreten",
				type: "error",
			});
			Logger.log("Error in downloadFile function", err);
		}
	};

	return {
		fetchFile,
		fetchFiles,
		deleteFiles,
		fetchFilesByProps,
		fileUrl,
		fetchFileFromPath,
		openFileInNewTab,
		downloadFile,
	};
};
